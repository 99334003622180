import React, { Component } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

class ServiceFormChat extends Component {
  render() {
    return (
      <HelmetProvider>
        <Helmet>
          <script> var tD=(new Date).toISOString().slice(0,10);window.sf3pid = "ot6Mg9TSz3XYqaOn2ScI";var u="https://dash.serviceform.com/embed/sf-pixel.js?"+tD,t=document.createElement("script");t.setAttribute("type","text/javascript"),t.setAttribute("src",u),t.async=!0,(document.getElementsByTagName("head")[0]||document.documentElement).appendChild(t);</script>
        </Helmet>
        <div className="service-form-chat-container" />
      </HelmetProvider>
    );
  }
}

export default ServiceFormChat;
