import { t } from "i18next"
import MigrationProcessImage from "assets/images/migration-process.png"
import "assets/styles/pages/HelpCenter.scss";
import { apis } from "msalConfig";

export const TabMigrationAPI = () => {
    return <>
        <div className="admin-box">
            <div className="help-center-bullet-list">
                <h1 className="text-style--Large-title text-color--brand10 mb-16">{t("MigrationAPI.OverviewTitle")}</h1>
                <ul>
                    <li>{t("MigrationAPI.OverviewBody.1")}</li>
                    <li>{t("MigrationAPI.OverviewBody.2")}</li>
                    <li>{t("MigrationAPI.OverviewBody.3")}</li>
                    <li>{t("MigrationAPI.OverviewBody.4")}</li>
                    <li>{t("MigrationAPI.OverviewBody.5")}</li>
                    <li>{t("MigrationAPI.OverviewBody.6")}</li>
                </ul>
                <br />
                <h1 className="text-style--Large-title text-color--brand10 mb-16">{t("MigrationAPI.MigrationProcessTitle")}</h1>
                <img src={MigrationProcessImage} alt="Migration process" />
                <br />
                <h1 className="text-style--Large-title text-color--brand10 mb-16">{t("MigrationAPI.TechnicalDetailsTitle")}</h1>
                <ul>
                    <li>{t("MigrationAPI.TechnicalDetailsBody.1")}</li>
                    <li>{t("MigrationAPI.TechnicalDetailsBody.2")}</li>
                    <li>{t("MigrationAPI.TechnicalDetailsBody.3")}</li>
                    <li>{t("MigrationAPI.TechnicalDetailsBody.4")}</li>
                    <li>{t("MigrationAPI.TechnicalDetailsBody.5")} <a href={apis.customerApi.endpoint + "/swagger"}>here</a></li>
                </ul>
            </div>
        </div>
    </>
}